/*
Project : Anduro Wallet
FileName : MARAHeaderVW.tsx
File Created : 08/04/2024
Purpose : This is the file that is used to display wallet header
*/
import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"

const MARAHeaderVW: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div>
        <div className="postioheader">
          <div className="walletHeader">
            <div className="subwallet">
              <div className="walletLogo">
                {/* <img src="/assets/images/logo.png" alt="Anduro Faucet" /> */}
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MARAHeaderVW
