import axios from "axios"
import MARACommonModel from "../model/MARACommonModel"
import config from "../utils/MARAConfig"


export const GetBalance = async (params: any) => {
  let url = `${params.baseUrl + config.CBTC_BALANCE}?`
  if (params.faucettype === "Alys") {
    url = `${params.baseUrl + config.ALYS_BALANCE}?`
  }
  return new Promise((resolve) => {
    axios
      .get(url)
      .then((response: any) => {
        const hasResult = MARACommonModel.checkStatus(response.data)
        let parsedResult
        if (hasResult) {
          parsedResult = {
            status: true,
            message: "data retrieved successfully",
            result: response.data.balance,
          }
        } else {
          parsedResult = {
            status: false,
            message: "No records were found.",
            result: 0, 
          }
        }
        resolve(parsedResult)
      })
      .catch(() => {
        const commonModel = {
          result: 0, 
        }
        resolve(commonModel)
      })
  })
}

export const SendFaucet = async (params: any) => {
  let url = `${params.baseUrl + config.CBTC_SEND}?`
  if (params.faucettype === "Alys" || params.faucettype === "Alpha") {
    url = `${params.baseUrl + config.ALYS_SEND}?`
  }
  return new Promise((resolve) => {
    axios
      .post(url, params)
      .then((response: any) => {
        console.log(response)
        let parsedResult
        if (response.data.status === true) {
          parsedResult = {
            status: true,
            response: response.data.response,
            message: response.data.message,
          }
        } else {
          parsedResult = {
            status: false,
            response: response.data.response,
            message: response.data.message,
          }
        }
        resolve(parsedResult)
      })
      .catch(() => {
        const commonModel = {
          status: false,
        }
        resolve(commonModel)
      })
  })
}

