import React from "react"
import { Routes, Route, BrowserRouter } from "react-router-dom"
import routes from "./route/route"
import MARAHeaderVW from "./UI/header/MARAHeaderVW"

function App() {
  return (
    <BrowserRouter>
      <div className="widset_parent">
        <MARAHeaderVW />
            <Routes>
              {routes.map((route) => (
                <Route path={route.path} element={<route.component />} key={route.path} />
              ))}
            </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
