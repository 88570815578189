import MARADashboardVC from "../components/dashboard/MARADashboardVC"
const routes = [
  {
    path: "/:type?",
    exact: true,
    component: MARADashboardVC,
  },
]

export default routes
