import * as React from "react"
import Button from "react-bootstrap/Button"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { Dropdown } from "react-bootstrap"
import { validateAddress, validateEVMAddress } from "../../utils/MARAUtils"
import { GetBalance, SendFaucet } from "../../services/FaucetService"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { publicIpv4} from 'public-ip';
import { useParams, useNavigate, Link } from 'react-router-dom';

const MARADashboardInterfaceVC: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = React.useState(true)
  const [showFaucet, setShowFaucet] = React.useState(false)
  const [selectedFaucet, setselectedFaucet] = React.useState("CBTC")
  const [address, setAddress] = React.useState("")
  const [fundError, setFundError] = React.useState("")
  const [balance, setBalance] = React.useState(0)
  const [selectedValue, setSelectedValue] = React.useState('');
  const [imageSrc, setImageSrc] = React.useState('/assets/images/cbtc-logo.png');
  const [isSending, setIsSending] = React.useState(false);


  React.useEffect(() => {
    if(params.type) {
      if(params.type==="coordinate") {
        handleSelectChange("0")
      } else if(params.type === "alys"){
        handleSelectChange("1")      
      } else {
        handleSelectChange("2")
      }
  //    handleSelectChange(params.type == "coordinate" ? "0" : "1")
    } else {
      handleSelectChange("0")
    }
  }, [])

  React.useEffect(() => {
    getBalance(selectedFaucet)
  }, [selectedFaucet])

  const getBalance = async (Faucet: String) => {
    GetBalance({
      baseUrl: process.env.REACT_APP_BASE_URL,
      faucettype: Faucet
    }).then((response: any) => {
      setIsLoading(false)
      if (response.status == false) {
        return
      }
      setBalance(response.result)
    })
  }


  const sendAction = async () => {
    setFundError("")
    const networkInfo = "chroma"
    const networkMode = process.env.REACT_APP_NETWORK
    let addressFinal: any
    if (selectedFaucet === "CBTC") {
      addressFinal = validateAddress(address.trim(), networkMode, networkInfo)
    } else {
      addressFinal = validateEVMAddress(address.trim())
    }
    if (addressFinal != "") {
      setIsSending(true)
      let params = {
        to_address: address.trim(),
        ip_address: await publicIpv4(),
        baseUrl: process.env.REACT_APP_BASE_URL,
        faucettype:selectedFaucet
      }
      const sendResult: any = await SendFaucet(params)
      if (!sendResult.status) {
        setIsLoading(false)
        if(selectedFaucet === "Alys") {
          toast.error(sendResult.message, { autoClose: 3000 })
        }
        else {
          toast.error(sendResult.message, { autoClose: 3000 })
        }
      } else {
          let url: any
          if(selectedFaucet === "CBTC") {
            url = `${process.env.REACT_APP_CBTC_EXPLORER}${sendResult.response}`
          } else if(selectedFaucet === "Alys") {
             url = `${process.env.REACT_APP_ALYS_EXPLORER}${sendResult.response}`   
          } else {
             url = `${process.env.REACT_APP_ALPHA_EXPLORER}${sendResult.response}`
          }
          toast.info(`${sendResult.message} Please click here for Transaction Info: ${sendResult.response}`, { autoClose: 10000, onClick: () => {
            window.open(url)
          }})
      } 
      setIsSending(false)     
    } else {
      // setFundError("Invalid address")
      toast.error("Invalid address !!", { autoClose: 3000 })
    }
  }

  const handleAddress = (event: any) => {
    let address = event.target.value
    setAddress(address.trim())
  }
  const handleSelectChange = (value: any) => {
    setSelectedValue(value);
    if (value === '1') {
      setShowFaucet(false)
      setImageSrc('/assets/images/alys.png')
      setselectedFaucet("Alys")
      navigate("/alys")
    } else if (value === '2'){
      setShowFaucet(false)
      setImageSrc('/assets/images/alys.png')
      setselectedFaucet("Alpha")
      navigate("/alpha")
    } else {
      setShowFaucet(true)
      setselectedFaucet("CBTC")
      setImageSrc('/assets/images/cbtc-logo.png')
      navigate("/coordinate")
    }
  };

  const SuccessMessage = (Faucettype: any, message: any, response: any) => (
    <div>
      <Link to='${process.env.REACT_APP_CBTC_EXPLORER + response}?'>{response}</Link>
    </div>
  )

  return (
    <div className="faucet-full-box">
      <div className="container px-2 px-sm-3">
        <div className="walletLogo">
          {selectedFaucet === "CBTC" ? (
            <img src="/assets/images/logo.png" alt="Anduro Faucet"
            />)
            : (<img src="/assets/images/big_alys.png" alt="Anduro Faucet" />)}
        </div>
        <h1 className="faucet-heading">Obtain CBTC/ALYS Testnet/ALYS Regtest coins every 24 hours for seamless and confident development</h1>
        <div className="faucet-box">
          <div className="container summary-container">
            <div className="mb-section">
              <div className="card mb-section shadow-sm">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-3 col-md-6">
                      <form className="search-container" id="search_form" method="post" action="./search">
                        <div className="faucet-container-inner">
                          <div className="faucinate-address">
                            <img className="address-image" src={imageSrc} alt="logo" />
                            <select id="search_type" value={selectedValue} onChange={(e)=>{handleSelectChange(e.target.value)}}>
                              <option value="0">0.1 cBTC</option>
                              <option value="1">0.1 aBTC</option>
                              <option value="2">0.1 tALPHA</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-xl-9 col-md-6">
                      <div className="">
                        <div className="cbtc-address">
                          {selectedFaucet === "CBTC" ? (<input
                            id="address"
                            onChange={handleAddress}
                            className=""
                            placeholder="Enter your CBTC Testnet address"
                            type="text"
                            autoComplete="off"
                          />)
                            : (<input
                              id="address"
                              onChange={handleAddress}
                              className=""
                              placeholder={selectedFaucet === "Alpha" ? "Enter your Alys Regtest address" : "Enter your Alys Testnet address"}
                              type="text"
                              autoComplete="off"
                            />)}
                        </div>
                        <div className="right_amount">
                          <div className="insufficient_funds">
                            <p>
                              <small id="funderror" className="funderror">
                                {fundError}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="address-send">
                      <button
                        id="next"
                        onClick={() => sendAction()}
                      >
                        {isSending? "Sending..." : selectedFaucet === "Alys" ? `Send 0.1 aBTC` : `Send 0.1 t${selectedFaucet}` }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MARADashboardInterfaceVC
