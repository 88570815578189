const MARACommonModel = {
  checkStatus(response: any) {
    try {
      if (response.data.status === true) {
        return true
      }
      return false
    } catch (error) {
      return true
    }
  },
}
export default MARACommonModel
