import * as React from "react"
import { useTheme } from "@mui/material/styles"
import MARADashboardInterfaceVC from "../dashboardInterface/MARADashboardInterfaceVC"


export interface TabPanelProps {
  children: React.ReactNode
  dir: string
  index: number
  value: number
}

const MARADashboardVC: React.FC = () => {
  const theme = useTheme()
  const stateValue = parseInt(localStorage.getItem("index") || "0", 10)
  const [value, setValue] = React.useState(stateValue)

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }
  return (
    <MARADashboardInterfaceVC />    
  )
}

export default MARADashboardVC
