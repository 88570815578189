import BIP32Factory from "bip32"
import ecc from "@bitcoinerlab/secp256k1"
import * as bip39 from "bip39"
import * as bitcoin from "bitcoinjs-lib"
import * as chroma from "chromajs-lib"
import { isEthereumAddress } from "validator"

const bip32 = BIP32Factory(ecc)
bitcoin.initEccLib(ecc)
chroma.initEccLib(ecc)

export const getChainInstance = (networkType: any) => {
  if (networkType === "bitcoin") {
    return bitcoin
  }
  return chroma
}

export const getMnemonic = () => {
  const mnemonic = bip39.generateMnemonic()
  return mnemonic
}
export const getNetwork = (network: String, networkType: String) => {
  if (network === "test") {
    return getChainInstance(networkType).networks.testnet
  }
  if (network === "main") {
    return getChainInstance(networkType).networks.bitcoin
  }
  return getChainInstance(networkType).networks.regtest
}

export const validateAddress = (address: any, networkString: any, networkType: String) => {
  const network = getNetwork(networkString, networkType)
  try {
    getChainInstance(networkType).address.toOutputScript(address, network)
    return address
  } catch (e) {
    try {
      getChainInstance(networkType).address.toOutputScript(address.toLowerCase(), network)
      return address.toLowerCase()
    } catch (e) {
      return ""
    }
  }
}

export const validateEVMAddress = (address: any) => {
  try {
    if (isEthereumAddress(address)) {     
      return address
    } else {
      return ""
    }
  } catch (error) {
    return ""
  }
}
